






















































































































































import {computed, defineComponent, ref} from '@vue/composition-api';
import {inject} from '@/container';
import {UseTaskEditor} from '@/tasks/composables/UseTaskEditor';
import TopicOntology from '@/topic-ontology/components/TopicOntology.vue';
import {TaskScopeType} from '@/tasks/types/TaskScopeType';
import InstitutionScopeExpansionPanel from '@/tasks/components/task-editor/InstitutionScopeExpansionPanel.vue';
import {ScopeEvents} from '@/tasks/types/ScopeEvents';
import TryItDialog from '@/tasks/components/TryItDialog.vue';
import {UseRouteTask} from '@/tasks/composables/UseRouteTask';
import STextarea from '@/common/components/STextarea.vue';
import SuccessSnackbarPlugin from '@/common/plugins/success-snackbar';
import router from '@/router';
import Task from '@/tasks/models/Task';
import {useRouter} from '@/router/composables';

export default defineComponent({
  name: 'TaskEditor',
  methods: {
    router() {
      return router;
    },
  },
  components: {STextarea, TryItDialog, InstitutionScopeExpansionPanel, TopicOntology},
  setup() {
    const isViewingTask = ref(false);

    const {task} = inject(UseRouteTask);
    const {
      editableTask,
      isPatching,
      patch: patchTaskInternal,
      reset: resetTask,
      selectedTopics,
      addFeedback,
      removeFeedback,
      expandedFeedbackPanel,
      addScope,
      removeScope,
      expandedScopePanel,
    } = inject(UseTaskEditor);

    async function patchTask() {
      patchTaskInternal()?.then(() => {
        (SuccessSnackbarPlugin as any).snackbar.open({
          messageTranslationKey: 'taskEditor.taskSaved',
          showButton: true,
          color: 'success',
          timeout: 3000,
        });
      });
    }

    async function nextTaskButton() {
      if (task.value) {
        const nextTask = await Task.api.nextTask(task.value.id);
        if (nextTask['Task'].length > 0) {
          await useRouter().push(`/tasks/${nextTask['Task'][0].$id}/metadata`);
        }
      }
    }

    async function previousTaskButton() {
      if (task.value) {
        const nextTask = await Task.api.previousTask(task.value.id);
        if (nextTask['Task'].length > 0) {
          await useRouter().push(`/tasks/${nextTask['Task'][0].$id}/metadata`);
        }
      }
    }

    return {
      task,
      isViewingTask,
      editableTask,
      isPatching,
      patchTask,
      resetTask,
      selectedTopics,
      addFeedback,
      removeFeedback,
      expandedFeedbackPanel,
      addScope,
      removeScope,
      expandedScopePanel,
      TaskScopeType,
      removeScopeEvent: ScopeEvents.SCOPE_REMOVE_SCOPE,
      nextTaskButton,
      previousTaskButton,
    };
  },
});
